import { ApiClient } from "@common/services/ApiClient";
import stateManager from "@common/services/stateManager"
import userManager from "./userManager";

class AppService {
    private store;

    constructor() {
    }

    public setStore(store) {
        this.store = store
        this.initActiveTenant()
    }

    public initActiveTenant() {
        const item = stateManager.get("activeTenant")
        this.setActiveTenant(item)
    }

    public setActiveTenant(item) {
        ApiClient.setImpersonatedTenantId(item && item._id)
        stateManager.set("activeTenant", item)
        this.store.commit("setActiveTenant", item)
    }

    public getActiveTenant() {
        return stateManager.get("activeTenant")
    }

    public deleteActiveTenant() {
        ApiClient.setImpersonatedTenantId(null)
        this.setActiveTenant(null)
    }

    public activeTenantValid() {
        return !userManager.checkRole(["systemAdmin", "admin"]) || this.getActiveTenant()
    }
}

export default new AppService()
