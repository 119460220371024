import SwaggerClient from 'swagger-client';
import { UserManager } from './UserManager';
import configProvider from './configProvider'

export class ApiClient {
    public static setErrorHandler(errorHandler: any) {
        this.errorHandler = errorHandler
    }

    public static setUserManager(userManager: UserManager) {
        this.userManager = userManager
    }

    public static setImpersonatedTenantId(value: string) {
        this.impersonatedTenantId = value
    }

    private static errorHandler: any
    private static userManager: UserManager;
    private static impersonatedTenantId: string;

    private apiName: string;
    private overriddenBaseUrl: string;
    private clientOptionsCallback: (options) => any;
    private clientForScenarios: Map<string, SwaggerClient> = new Map();

    constructor(apiName: string, overriddenBaseUrl?: string, clientOptionsCallback?: (options) => any) {
        this.apiName = apiName
        this.overriddenBaseUrl = overriddenBaseUrl
        this.clientOptionsCallback = clientOptionsCallback
    }

    public reset() {
        this.clientForScenarios = new Map();
    }

    public async call(api: string, operatorId: string, params?, body?, includeUploadFiles = false, scenario?: string) {
        return await this.try(() => {
            return this.callRaw(api, operatorId, params, body, includeUploadFiles, scenario)
        })
    }

    public async callRaw(api: string, operatorId: string, params?, body?, includeUploadFiles = false, scenario?: string) {
        if (configProvider.get("DEBUG")) {
            scenario = undefined
        }

        const client = await this.initializeIfNeed(scenario)
        if (!params) {
            params = {}
        }
        if (body) {
            {
                const newBody = {}
                for (const k in body) {
                    const v = body[k]
                    if (v !== undefined) {
                        newBody[k] = v
                    }
                }
                body = newBody
            }
            body = {
                requestBody: body,
                requestContentType: includeUploadFiles && "multipart/form-data"
            }
        }
        operatorId = `${this.capitalizeFirstLetter(api)}Controller_${operatorId}`
        try {
            const data = await client.apis[api][operatorId](params, body)
            return data.body
        } catch (originErr) {
            if (!scenario) {
                if ((originErr as any).statusCode === 401) {
                    throw originErr
                }
            }
            const err: any = originErr
            let message =
                err.response && err.response.body && err.response.body.message;
            if (!message) {
                message = err;
            }
            throw new Error(message)
        }
    }

    public makeDownloadUrl(api: string, path?: string, params: any = {}, ignoreAccessToken = false, scenario?: string) {
        if (configProvider.get("DEBUG")) {
            scenario = undefined
        }
        const items = [this.baseUrl()]
        if (api) {
            items.push(api)
        }
        if (path) {
            items.push(path)
        }
        const url = new URL(items.join("/"))
        if (!ignoreAccessToken) {
            params.access_token = ApiClient.userManager.getAccessTokenForScenario(scenario)
        }
        for (const k in params) {
            const v = params[k]
            if (v === undefined || v === null) {
                continue
            }
            url.searchParams.append(k, v)
        }
        return url.href
    }

    public download(api: string, path: string, params: any = {}, scenario?: string) {
        const url = this.makeDownloadUrl(api, path, params, false, scenario)
        location.href = url
    }

    public async getClient(scenario?: string): SwaggerClient {
        return await this.initializeIfNeed(scenario)
    }

    private async try(f: any) {
        try {
            return await f()
        } catch (err) {
            ApiClient.errorHandler && ApiClient.errorHandler(err)
        }
    }

    private baseUrl(): string {
        let value = configProvider.get("BASE_URL")
        if (this.overriddenBaseUrl) {
            value = this.overriddenBaseUrl
        }
        if (!value) {
            value = `${location.protocol}//${location.host}`
        }
        return `${value}/${this.apiName}`
    }

    private async initializeIfNeed(scenario = ""): Promise<SwaggerClient> {
        if (!this.clientForScenarios[scenario]) {
            let options = {
                url: `${this.baseUrl()}/api-json`,
                authorizations: {
                    bearer: {
                        value: ApiClient.userManager.getAccessTokenForScenario(scenario),
                    }
                },
                requestInterceptor: req => {
                    if (ApiClient.impersonatedTenantId) {
                        req.headers["immpersonated-tenant-id"] = ApiClient.impersonatedTenantId
                    }
                },
            }
            if (this.clientOptionsCallback) {
                options = this.clientOptionsCallback(options)
            }
            this.clientForScenarios[scenario] = await SwaggerClient(options);
        }
        return this.clientForScenarios[scenario]
    }

    private capitalizeFirstLetter(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
}
