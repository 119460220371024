export const timeZones = [
    { abbr: "ACDT", name: "Australian Central Daylight Saving Time", utc: "UTC+10:30" },
    { abbr: "ACST", name: "Australian Central Standard Time", utc: "UTC+09:30" },
    { abbr: "ACT", name: "Acre Time", utc: "UTC-05" },
    { abbr: "ACT", name: "ASEAN Common Time (proposed)", utc: "UTC+08:00" },
    { abbr: "ACWST", name: "Australian Central Western Standard Time (unofficial)", utc: "UTC+08:45" },
    { abbr: "ADT", name: "Atlantic Daylight Time", utc: "UTC-03" },
    { abbr: "AEDT", name: "Australian Eastern Daylight Saving Time", utc: "UTC+11" },
    { abbr: "AEST", name: "Australian Eastern Standard Time", utc: "UTC+10" },
    { abbr: "AET", name: "Australian Eastern Time", utc: "UTC+10/UTC+11" },
    { abbr: "AFT", name: "Afghanistan Time", utc: "UTC+04:30" },
    { abbr: "AKDT", name: "Alaska Daylight Time", utc: "UTC-08" },
    { abbr: "AKST", name: "Alaska Standard Time", utc: "UTC-09" },
    { abbr: "ALMT", name: "Alma-Ata Time[1]", utc: "UTC+06" },
    { abbr: "AMST", name: "Amazon Summer Time (Brazil)[2]", utc: "UTC-03" },
    { abbr: "AMT", name: "Amazon Time (Brazil)[3]", utc: "UTC-04" },
    { abbr: "AMT", name: "Armenia Time", utc: "UTC+04" },
    { abbr: "ANAT", name: "Anadyr Time[4]", utc: "UTC+12" },
    { abbr: "AQTT", name: "Aqtobe Time[5]", utc: "UTC+05" },
    { abbr: "ART", name: "Argentina Time", utc: "UTC-03" },
    { abbr: "AST", name: "Arabia Standard Time", utc: "UTC+03" },
    { abbr: "AST", name: "Atlantic Standard Time", utc: "UTC-04" },
    { abbr: "AWST", name: "Australian Western Standard Time", utc: "UTC+08" },
    { abbr: "AZOST", name: "Azores Summer Time", utc: "UTC±00" },
    { abbr: "AZOT", name: "Azores Standard Time", utc: "UTC-01" },
    { abbr: "AZT", name: "Azerbaijan Time", utc: "UTC+04" },
    { abbr: "BNT", name: "Brunei Time", utc: "UTC+08" },
    { abbr: "BIOT", name: "British Indian Ocean Time", utc: "UTC+06" },
    { abbr: "BIT", name: "Baker Island Time", utc: "UTC-12" },
    { abbr: "BOT", name: "Bolivia Time", utc: "UTC-04" },
    { abbr: "BRST", name: "Brasília Summer Time", utc: "UTC-02" },
    { abbr: "BRT", name: "Brasília Time", utc: "UTC-03" },
    { abbr: "BST", name: "Bangladesh Standard Time", utc: "UTC+06" },
    { abbr: "BST", name: "Bougainville Standard Time[6]", utc: "UTC+11" },
    { abbr: "BST", name: "British Summer Time (British Standard Time from Mar 1968 to Oct 1971)", utc: "UTC+01" },
    { abbr: "BTT", name: "Bhutan Time", utc: "UTC+06" },
    { abbr: "CAT", name: "Central Africa Time", utc: "UTC+02" },
    { abbr: "CCT", name: "Cocos Islands Time", utc: "UTC+06:30" },
    { abbr: "CDT", name: "Central Daylight Time (North America)", utc: "UTC-05" },
    { abbr: "CDT", name: "Cuba Daylight Time[7]", utc: "UTC-04" },
    { abbr: "CEST", name: "Central European Summer Time", utc: "UTC+02" },
    { abbr: "CET", name: "Central European Time", utc: "UTC+01" },
    { abbr: "CHADT", name: "Chatham Daylight Time", utc: "UTC+13:45" },
    { abbr: "CHAST", name: "Chatham Standard Time", utc: "UTC+12:45" },
    { abbr: "CHOT", name: "Choibalsan Standard Time", utc: "UTC+08" },
    { abbr: "CHOST", name: "Choibalsan Summer Time", utc: "UTC+09" },
    { abbr: "CHST", name: "Chamorro Standard Time", utc: "UTC+10" },
    { abbr: "CHUT", name: "Chuuk Time", utc: "UTC+10" },
    { abbr: "CIST", name: "Clipperton Island Standard Time", utc: "UTC-08" },
    { abbr: "CKT", name: "Cook Island Time", utc: "UTC-10" },
    { abbr: "CLST", name: "Chile Summer Time", utc: "UTC-03" },
    { abbr: "CLT", name: "Chile Standard Time", utc: "UTC-04" },
    { abbr: "COST", name: "Colombia Summer Time", utc: "UTC-04" },
    { abbr: "COT", name: "Colombia Time", utc: "UTC-05" },
    { abbr: "CST", name: "Central Standard Time (North America)", utc: "UTC-06" },
    { abbr: "CST", name: "China Standard Time", utc: "UTC+08" },
    { abbr: "CST", name: "Cuba Standard Time", utc: "UTC-05" },
    { abbr: "CT", name: "Central Time", utc: "UTC-06/UTC-05" },
    { abbr: "CVT", name: "Cape Verde Time", utc: "UTC-01" },
    { abbr: "CWST", name: "Central Western Standard Time (Australia) unofficial", utc: "UTC+08:45" },
    { abbr: "CXT", name: "Christmas Island Time", utc: "UTC+07" },
    { abbr: "DAVT", name: "Davis Time", utc: "UTC+07" },
    { abbr: "DDUT", name: "Dumont d'Urville Time", utc: "UTC+10" },
    { abbr: "DFT", name: "AIX-specific equivalent of Central European Time[NB 1]", utc: "UTC+01" },
    { abbr: "EASST", name: "Easter Island Summer Time", utc: "UTC-05" },
    { abbr: "EAST", name: "Easter Island Standard Time", utc: "UTC-06" },
    { abbr: "EAT", name: "East Africa Time", utc: "UTC+03" },
    { abbr: "ECT", name: "Eastern Caribbean Time (does not recognise DST)", utc: "UTC-04" },
    { abbr: "ECT", name: "Ecuador Time", utc: "UTC-05" },
    { abbr: "EDT", name: "Eastern Daylight Time (North America)", utc: "UTC-04" },
    { abbr: "EEST", name: "Eastern European Summer Time", utc: "UTC+03" },
    { abbr: "EET", name: "Eastern European Time", utc: "UTC+02" },
    { abbr: "EGST", name: "Eastern Greenland Summer Time", utc: "UTC±00" },
    { abbr: "EGT", name: "Eastern Greenland Time", utc: "UTC-01" },
    { abbr: "EST", name: "Eastern Standard Time (North America)", utc: "UTC-05" },
    { abbr: "ET", name: "Eastern Time (North America)", utc: "UTC-05 / UTC-04" },
    { abbr: "FET", name: "Further-eastern European Time", utc: "UTC+03" },
    { abbr: "FJT", name: "Fiji Time", utc: "UTC+12" },
    { abbr: "FKST", name: "Falkland Islands Summer Time", utc: "UTC-03" },
    { abbr: "FKT", name: "Falkland Islands Time", utc: "UTC-04" },
    { abbr: "FNT", name: "Fernando de Noronha Time", utc: "UTC-02" },
    { abbr: "GALT", name: "Galápagos Time", utc: "UTC-06" },
    { abbr: "GAMT", name: "Gambier Islands Time", utc: "UTC-09" },
    { abbr: "GET", name: "Georgia Standard Time", utc: "UTC+04" },
    { abbr: "GFT", name: "French Guiana Time", utc: "UTC-03" },
    { abbr: "GILT", name: "Gilbert Island Time", utc: "UTC+12" },
    { abbr: "GIT", name: "Gambier Island Time", utc: "UTC-09" },
    { abbr: "GMT", name: "Greenwich Mean Time", utc: "UTC±00" },
    { abbr: "GST", name: "South Georgia and the South Sandwich Islands Time", utc: "UTC-02" },
    { abbr: "GST", name: "Gulf Standard Time", utc: "UTC+04" },
    { abbr: "GYT", name: "Guyana Time", utc: "UTC-04" },
    { abbr: "HDT", name: "Hawaii–Aleutian Daylight Time", utc: "UTC-09" },
    { abbr: "HAEC", name: "Heure Avancée d'Europe Centrale French-language name for CEST", utc: "UTC+02" },
    { abbr: "HST", name: "Hawaii–Aleutian Standard Time", utc: "UTC-10" },
    { abbr: "HKT", name: "Hong Kong Time", utc: "UTC+08" },
    { abbr: "HMT", name: "Heard and McDonald Islands Time", utc: "UTC+05" },
    { abbr: "HOVST", name: "Hovd Summer Time (not used from 2017-present)", utc: "UTC+08" },
    { abbr: "HOVT", name: "Hovd Time", utc: "UTC+07" },
    { abbr: "ICT", name: "Indochina Time", utc: "UTC+07" },
    { abbr: "IDLW", name: "International Date Line West time zone", utc: "UTC-12" },
    { abbr: "IDT", name: "Israel Daylight Time", utc: "UTC+03" },
    { abbr: "IOT", name: "Indian Ocean Time", utc: "UTC+03" },
    { abbr: "IRDT", name: "Iran Daylight Time", utc: "UTC+04:30" },
    { abbr: "IRKT", name: "Irkutsk Time", utc: "UTC+08" },
    { abbr: "IRST", name: "Iran Standard Time", utc: "UTC+03:30" },
    { abbr: "IST", name: "Indian Standard Time", utc: "UTC+05:30" },
    { abbr: "IST", name: "Irish Standard Time[8]", utc: "UTC+01" },
    { abbr: "IST", name: "Israel Standard Time", utc: "UTC+02" },
    { abbr: "JST", name: "Japan Standard Time", utc: "UTC+09" },
    { abbr: "KALT", name: "Kaliningrad Time", utc: "UTC+02" },
    { abbr: "KGT", name: "Kyrgyzstan Time", utc: "UTC+06" },
    { abbr: "KOST", name: "Kosrae Time", utc: "UTC+11" },
    { abbr: "KRAT", name: "Krasnoyarsk Time", utc: "UTC+07" },
    { abbr: "KST", name: "Korea Standard Time", utc: "UTC+09" },
    { abbr: "LHST", name: "Lord Howe Standard Time", utc: "UTC+10:30" },
    { abbr: "LHST", name: "Lord Howe Summer Time", utc: "UTC+11" },
    { abbr: "LINT", name: "Line Islands Time", utc: "UTC+14" },
    { abbr: "MAGT", name: "Magadan Time", utc: "UTC+12" },
    { abbr: "MART", name: "Marquesas Islands Time", utc: "UTC-09:30" },
    { abbr: "MAWT", name: "Mawson Station Time", utc: "UTC+05" },
    { abbr: "MDT", name: "Mountain Daylight Time (North America)", utc: "UTC-06" },
    { abbr: "MET", name: "Middle European Time (same zone as CET)", utc: "UTC+01" },
    { abbr: "MEST", name: "Middle European Summer Time (same zone as CEST)", utc: "UTC+02" },
    { abbr: "MHT", name: "Marshall Islands Time", utc: "UTC+12" },
    { abbr: "MIST", name: "Macquarie Island Station Time", utc: "UTC+11" },
    { abbr: "MIT", name: "Marquesas Islands Time", utc: "UTC-09:30" },
    { abbr: "MMT", name: "Myanmar Standard Time", utc: "UTC+06:30" },
    { abbr: "MSK", name: "Moscow Time", utc: "UTC+03" },
    { abbr: "MST", name: "Malaysia Standard Time", utc: "UTC+08" },
    { abbr: "MST", name: "Mountain Standard Time (North America)", utc: "UTC-07" },
    { abbr: "MUT", name: "Mauritius Time", utc: "UTC+04" },
    { abbr: "MVT", name: "Maldives Time", utc: "UTC+05" },
    { abbr: "MYT", name: "Malaysia Time", utc: "UTC+08" },
    { abbr: "NCT", name: "New Caledonia Time", utc: "UTC+11" },
    { abbr: "NDT", name: "Newfoundland Daylight Time", utc: "UTC-02:30" },
    { abbr: "NFT", name: "Norfolk Island Time", utc: "UTC+11" },
    { abbr: "NOVT", name: "Novosibirsk Time [9]", utc: "UTC+07" },
    { abbr: "NPT", name: "Nepal Time", utc: "UTC+05:45" },
    { abbr: "NST", name: "Newfoundland Standard Time", utc: "UTC-03:30" },
    { abbr: "NT", name: "Newfoundland Time", utc: "UTC-03:30" },
    { abbr: "NUT", name: "Niue Time", utc: "UTC-11" },
    { abbr: "NZDT", name: "New Zealand Daylight Time", utc: "UTC+13" },
    { abbr: "NZST", name: "New Zealand Standard Time", utc: "UTC+12" },
    { abbr: "OMST", name: "Omsk Time", utc: "UTC+06" },
    { abbr: "ORAT", name: "Oral Time", utc: "UTC+05" },
    { abbr: "PDT", name: "Pacific Daylight Time (North America)", utc: "UTC-07" },
    { abbr: "PET", name: "Peru Time", utc: "UTC-05" },
    { abbr: "PETT", name: "Kamchatka Time", utc: "UTC+12" },
    { abbr: "PGT", name: "Papua New Guinea Time", utc: "UTC+10" },
    { abbr: "PHOT", name: "Phoenix Island Time", utc: "UTC+13" },
    { abbr: "PHT", name: "Philippine Time", utc: "UTC+08" },
    { abbr: "PHST", name: "Philippine Standard Time", utc: "UTC+08" },
    { abbr: "PKT", name: "Pakistan Standard Time", utc: "UTC+05" },
    { abbr: "PMDT", name: "Saint Pierre and Miquelon Daylight Time", utc: "UTC-02" },
    { abbr: "PMST", name: "Saint Pierre and Miquelon Standard Time", utc: "UTC-03" },
    { abbr: "PONT", name: "Pohnpei Standard Time", utc: "UTC+11" },
    { abbr: "PST", name: "Pacific Standard Time (North America)", utc: "UTC-08" },
    { abbr: "PWT", name: "Palau Time[10]", utc: "UTC+09" },
    { abbr: "PYST", name: "Paraguay Summer Time[11]", utc: "UTC-03" },
    { abbr: "PYT", name: "Paraguay Time[12]", utc: "UTC-04" },
    { abbr: "RET", name: "Réunion Time", utc: "UTC+04" },
    { abbr: "ROTT", name: "Rothera Research Station Time", utc: "UTC-03" },
    { abbr: "SAKT", name: "Sakhalin Island Time", utc: "UTC+11" },
    { abbr: "SAMT", name: "Samara Time", utc: "UTC+04" },
    { abbr: "SAST", name: "South African Standard Time", utc: "UTC+02" },
    { abbr: "SBT", name: "Solomon Islands Time", utc: "UTC+11" },
    { abbr: "SCT", name: "Seychelles Time", utc: "UTC+04" },
    { abbr: "SDT", name: "Samoa Daylight Time", utc: "UTC-10" },
    { abbr: "SGT", name: "Singapore Time", utc: "UTC+08" },
    { abbr: "SLST", name: "Sri Lanka Standard Time", utc: "UTC+05:30" },
    { abbr: "SRET", name: "Srednekolymsk Time", utc: "UTC+11" },
    { abbr: "SRT", name: "Suriname Time", utc: "UTC-03" },
    { abbr: "SST", name: "Samoa Standard Time", utc: "UTC-11" },
    { abbr: "SST", name: "Singapore Standard Time", utc: "UTC+08" },
    { abbr: "SYOT", name: "Showa Station Time", utc: "UTC+03" },
    { abbr: "TAHT", name: "Tahiti Time", utc: "UTC-10" },
    { abbr: "THA", name: "Thailand Standard Time", utc: "UTC+07" },
    { abbr: "TFT", name: "French Southern and Antarctic Time[13]", utc: "UTC+05" },
    { abbr: "TJT", name: "Tajikistan Time", utc: "UTC+05" },
    { abbr: "TKT", name: "Tokelau Time", utc: "UTC+13" },
    { abbr: "TLT", name: "Timor Leste Time", utc: "UTC+09" },
    { abbr: "TMT", name: "Turkmenistan Time", utc: "UTC+05" },
    { abbr: "TRT", name: "Turkey Time", utc: "UTC+03" },
    { abbr: "TOT", name: "Tonga Time", utc: "UTC+13" },
    { abbr: "TVT", name: "Tuvalu Time", utc: "UTC+12" },
    { abbr: "ULAST", name: "Ulaanbaatar Summer Time", utc: "UTC+09" },
    { abbr: "ULAT", name: "Ulaanbaatar Standard Time", utc: "UTC+08" },
    { abbr: "UTC", name: "Coordinated Universal Time", utc: "UTC±00" },
    { abbr: "UYST", name: "Uruguay Summer Time", utc: "UTC-02" },
    { abbr: "UYT", name: "Uruguay Standard Time", utc: "UTC-03" },
    { abbr: "UZT", name: "Uzbekistan Time", utc: "UTC+05" },
    { abbr: "VET", name: "Venezuelan Standard Time", utc: "UTC-04" },
    { abbr: "VLAT", name: "Vladivostok Time", utc: "UTC+10" },
    { abbr: "VOLT", name: "Volgograd Time", utc: "UTC+03" },
    { abbr: "VOST", name: "Vostok Station Time", utc: "UTC+06" },
    { abbr: "VUT", name: "Vanuatu Time", utc: "UTC+11" },
    { abbr: "WAKT", name: "Wake Island Time", utc: "UTC+12" },
    { abbr: "WAST", name: "West Africa Summer Time", utc: "UTC+02" },
    { abbr: "WAT", name: "West Africa Time", utc: "UTC+01" },
    { abbr: "WEST", name: "Western European Summer Time", utc: "UTC+01" },
    { abbr: "WET", name: "Western European Time", utc: "UTC±00" },
    { abbr: "WIB", name: "Western Indonesian Time", utc: "UTC+07" },
    { abbr: "WIT", name: "Eastern Indonesian Time", utc: "UTC+09" },
    { abbr: "WITA", name: "Central Indonesia Time", utc: "UTC+08" },
    { abbr: "WGST", name: "West Greenland Summer Time[14]", utc: "UTC-02" },
    { abbr: "WGT", name: "West Greenland Time[15]", utc: "UTC-03" },
    { abbr: "WST", name: "Western Standard Time", utc: "UTC+08" },
    { abbr: "YAKT", name: "Yakutsk Time", utc: "UTC+09" },
    { abbr: "YEKT", name: "Yekaterinburg Time", utc: "UTC+05" },
]
