import './plugins/vuetifyx';
import "./assets/css/style.scss"
import 'leaflet/dist/leaflet.css';

import { LMap, LMarker, LTileLayer } from 'vue2-leaflet';

import { Icon } from 'leaflet';
import Layout from './views/Layout.vue'
import Vue from 'vue'
import i18n from './plugins/i18n';
import router from './plugins/router'
import store from './plugins/store'
import vuetify from '@common/plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps'

// import * as Sentry from "@sentry/vue";



type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.config.productionTip = false
// Sentry.init({
//   Vue,
//   dsn: window.vueConfigProvider.SENTRY_DNS || process.env.VUE_APP_SENTRY_DNS || "https://84a49ca6b77815d9e4f1c516a251ace2@sentry.khongan.com/7",
//   integrations: [
//     Sentry.browserTracingIntegration({
//       router
//     }),
//     Sentry.replayIntegration(),
//   ],
//   tracePropagationTargets: [window.vueConfigProvider.BASE_URL, window.vueConfigProvider.BASE_URL.replace("https://", ""), window.vueConfigProvider.BASE_URL.replace("http://", ""), window.location.host],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   debug: false,
//   logErrors: true,
//   attachProps: true,
//   attachStacktrace: true,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   beforeSend(event) {
//     // exclude all events that have no stack trace
//     if (event.exception && event.exception.values[0].stacktrace) {
//       console.log(event);
//       return event;
//     } else {
//       return null;
//     }
//   },
// });

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBudMIbeP7AcLgO1Y2GM2A4qyyC9z9AiOE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})



new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(Layout)
}).$mount('#app')
