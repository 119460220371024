import Vue from "vue"
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errorShown: false,
    errorMessage: "",
    activeTenant: null,
  },
  mutations: {
    setErrorMessage(state, message) {
      console.log(message)
      state.errorMessage = message
      state.errorShown = !!message
    },
    setActiveTenant(state, item) {
      state.activeTenant = item
    },
  },
  actions: {
  },
  modules: {
  }
})
