











import Vue from "vue";
import { ApiClient } from "@common/services/ApiClient";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";
import { timeZones } from "@/constants/setting";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      errorShown: false,
      error: "",
      loading: false,
    };
  },
  async created() {
    appService.setStore(this.$store);
    userManager.setVuetify(this.$vuetify);

    const self = this;
    ApiClient.setUserManager(userManager);
    ApiClient.setErrorHandler((err) => {
      if (err.statusCode === 401) {
        userManager.deleteAccessToken();
        self.$router.push("/login");
        return;
      }
      console.log(err);
      self.$store.commit("setErrorMessage", err?.response?.body?.message || err);
    });

    const userInfo = userManager.getUserInfo();
    if (userInfo) {
      try {
        this.loading = true;
        const setting = await coreApiClient.call("users", "getSetting", {
          id: userInfo._id,
        });
        if (setting && setting.timeZone) {
          const timeZone = timeZones.find(({ abbr }) => abbr === setting.timeZone);
          if (timeZone) {
            const utcStr = timeZone.utc.split("/")[0].slice(3);
            const isPositive = utcStr[0] === "+";
            const parts = utcStr.slice(1).split(":");
            const hours = parseInt(parts[0], 10);
            let minutes = 0;
            if (parts.length > 1) {
              minutes = parseInt(parts[1], 10);
            }
            let offset = (hours * 60 + minutes) * 60 * 1000;
            if (!isPositive) {
              offset = -offset;
            }
            stateManager.set("currentTimeZoneOffset", offset, true);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  },
});
