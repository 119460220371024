import DisplaySetting from "../x-data-table/DisplaySetting.vue"

export default {
  attrs: {
    class: "elevation-1",
    'fixed-header': true,
    "items-per-page": 50,
    "footer-props": {
      'items-per-page-options': [10, 20, 50, 100, 200, 500],
    },
  },
  content: {
    errorNotifySnackBar: {
      attrs: {
        color: "red",
        "multi-line": true,
        timeout: 3000,
        icon: 'mdi-alert-circle',
        iconAttrs: {
        }
      },
      content: {
        template: {
          button: {
            attrs: {
            }
          }
        }
      }
    },
    infoNotifySnackBar: {
      attrs: {
        "multi-line": true,
        timeout: 3000,
        color: "primary"
      },
      content: {
        template: {
          button: {
            attrs: {
              dark: true,
            }
          }
        }
      }
    },
    mainCard: {
      attrs: {}
    },
    mainToolbar: {
      attrs: {
        dense: true,
        flat: true,
      },
    },
    filters: {
      header: {
        icon: "mdi-table-search",
        text: "Advanced search"
      },
      colLength: 3,
      searchButton: {
        attrs: {
          dark: true,
          color: "primary",
          xClass: "px-4 mb-2"
        },
        content: {
          text: "Search",
          "icon-pre": "mdi-table-search",
          iconAttrs: {
            class: "mr-1"
          }
        },
        ext: {
          hidden: false,
        }
      },
      clearButton: {
        attrs: {
          color: "red",
          small: true,
          outlined: true
        },
        content: {
          text: "Clear",
          "icon-pre": "mdi-close",
          iconAttrs: {
            class: "mr-1"
          }
        },
        ext: {
          hidden: false,
        }
      },
      template: {
        filterInput: {}
      }
    },
    search: {
      searchInput: {
        attrs: {
          placeholder: "Search",
          "append-icon": "mdi-magnify",
          "single-line": true,
          "hide-details": true,
          extraStyles: {
            "width": "400px"
          }
        }
      },
    },
    topActionButtons: {
      showDisplaySetting: {
        attrs: {
          color: "default",
          dark: false,
        },
        content: {
          text: "Display Setting",
          icon: "mdi-table",
          attrsMaker(attrs: any = {}, self) {
            const dataTable = self.context()
            if (dataTable.displaySettingDirty()) {
              attrs.color = "primary"
              attrs.dark = true
            }
            return attrs
          }
        },
        target: {
          menu: {
            attrs: {
              "close-on-content-click": false,
              "offset-y": true,
              bottom: true,
              "nudge-bottom": "10px",
            },
            content: {
              cardContent: {
                title: "Select fields to display",
                content: {
                  type: DisplaySetting,
                  attrs: {
                    xOptions: {
                      content: {
                        displayFields: {},
                        template: {
                          checkbox: {
                            attrs: {}
                          },
                        }
                      },
                      on: {
                        changed({ self, data }) {
                          const menu = self.context()
                          const btn = menu.context()
                          const dataTable = btn.context()
                          dataTable.updateDisplaySetting(data)
                        }
                      }
                    }
                  },
                },
                topButtons: {
                  restore: {
                    content: {
                      icon: "mdi-restore"
                    },
                    target: {
                      tooltip: { content: { text: "Restore to default" } }
                    },
                    on: {
                      xClick({ self }) {
                        const menu = self.context()
                        const btn = menu.context()
                        const dataTable = btn.context()
                        const displaySettingForm =
                          menu.cardContentContainer.value
                        dataTable.saveDisplaySetting(null)
                        displaySettingForm.reset({})
                      }
                    },
                    ext: {
                      condition(menu) {
                        const btn = menu.context()
                        const dataTable = btn.context()
                        return dataTable.displaySettingDirty()
                      }
                    }
                  }
                },
                buttons: {
                  save: {
                    content: {
                      text: "Remember for later"
                    },
                    on: {
                      xClick({ self }) {
                        const menu = self.context()
                        const btn = menu.context()
                        const dataTable = btn.context()
                        dataTable.saveDisplaySetting()
                        menu.hide()
                      }
                    }
                  }
                }
              }
            }
          }
        },
        ext: {
          sortPriority: 10,
          condition(self) {
            return !self.$vuetify.breakpoint.xsOnly
          }
        }
      }
    },
    template: {
      multiSelectActionButton: {
        attrs: {
          text: false,
          small: false,
          dark: true,
          fab: true,
          xClass: "ml-4"
        },
        content: {
          iconAttrs: {
          }
        },
        action: {
          targetName: "confirm",
          confirmMessage(dataTable, action) {
            return `Are you sure to apply action <b>${action.name}</b> to ${dataTable.model.value.length} item(s)<br/>This cannot be undone!`;
          },
        },
      },
      topActionButton: {
        attrs: {
          dark: true,
          color: "primary",
          extraClasses: {
            "ml-2": true,
            "my-1": true,
          }
        },
      },
      itemActionButton: {
        attrs: {
          "min-width": "unset",
          ignoreSelection: true,
          class: "px-1",
          small: true,
          text: true,
          color: "default"
        },
        content: {
          iconAttrs: {
            class: "pl-0",
          },
          attrs: {
            class: 'item-action-button'
          }
        }
      },
      checkbox: {
        attrs: {
          color: "primary",
        }
      },
    },
  }
}
