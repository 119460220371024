import { UserManager as BaseUserManager } from "@common/services/UserManager"

class UserManager extends BaseUserManager {
    private vuetify: any

    public setVuetify(vuetify: any): void {
        this.vuetify = vuetify
    }

    public isGuest(): boolean {
        return !this.getUserInfo()
    }

    public isUserMobile(): boolean {
        return (this.isGuest() || this.checkRole(["user"], true)) && this.vuetify?.breakpoint?.mobile
    }

    public getTenantId(): string {    
        return this.getUserInfo()?.tenant?.id || ""
    }

    protected superRole(): string {
        return "systemAdmin"
    }
}

export default new UserManager()
